import 'react-app-polyfill/ie11'
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Loadable from 'react-loadable';


import ComponentLoading from './ComponentLoading';

import {
    BrowserView,
    MobileView
} from "react-device-detect";



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const MobileComponent = Loadable({
    loader: () => import('./Mobile'),
    loading: ComponentLoading
});

const WebComponent = Loadable({
    loader: () => import('./Web'),
    loading: ComponentLoading
});

ReactDOM.render(
  
     <BrowserRouter basename={baseUrl} >
        <div>
             <BrowserView>
              <WebComponent />

            </BrowserView>
            <MobileView>
                <MobileComponent />
            </MobileView>
        </div>

    </BrowserRouter>,
    rootElement);

//registerServiceWorker();