import React, { Component } from 'react';
import ReactLoading from "react-loading";

export class ComponentLoading extends Component {
    render() {
        return (
            <div>

                <div className='k-loading-mask'>
                    <span className="k-loading-text"></span>
                    <div className="k-loading-image">
                        <ReactLoading type="bubbles" color="#42ab9e" className="loadingSpinner" />
                    </div>
                    <div className="k-loading-color"></div>
                </div>

            </div>
        );
    }
}

export default ComponentLoading